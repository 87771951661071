import {http} from './config';

export default {
    index: () => http.get('api/v1/configuracao-area-publica'),

    create: data => http.post('api/v1/configuracao-area-publica', data),

    show: id => http.get(`api/v1/configuracao-area-publica/${id}`),

    update: data => http.put(`api/v1/configuracao-area-publica/${data.id}`, data),

    list: () => http.get('api/v1/configuracao-area-publica/list'),

    duplicate: id => http.get(`api/v1/configuracao-area-publica/duplicar/${id}`),

    delete: id => http.delete(`api/v1/configuracao-area-publica/${id}`),
};