<template>
    <div>
        <page-header :pageTitle="title" @second-action="$router.push({name: 'indexConfigPublicArea'})"
                     :btn-new="getTitleButton()" :icon-btn-new="getIcon()" second-btn="ACTIONS.BACK"
                     @new-type="action === 'view' ? toEdit() : save()" second-icon-btn-new="icon-arrow_back"/>
        <div class="card" v-if="!publicAreaConfig">
            <div class="card-header"/>
            <div class="card-body pt-0">
                <div class="text-center">
                    <h5>{{ t('CONFIG_CLICK_SIGN.NONE_FOUND') }}</h5>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="row gutters">
                <div class="col-xl-4 col-lg-8 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                        <label for="name" class="required">{{ t('CONFIG_SIMULATOR.NAME') }}: </label>
                        <input type="text" id="name" v-model="publicAreaConfig.name" name="name"
                               class="form-control" :disabled="disableInputs" placeholder="Digite o nome">
                    </div>
                </div>
            </div>
            <div class="row gutters">
                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" id="action_show_maps_on_public_area"
                               name="action_show_maps_on_public_area" :disabled="disableInputs"
                               v-model="publicAreaConfig.action_show_maps_on_public_area">
                        <label class="custom-control-label pointer" for="action_show_maps_on_public_area">
                            {{ t('CONFIG_PUBLIC_AREA.DISPLAY_MAP_ACCESS_IN_PUBLIC_AREAS') }}
                            <i title="Exibe o acesso ao link do mapa nas áreas públicas"
                               class="ml-1 icon-info-with-circle"/>
                        </label>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" id="action_reserve_lot_on_public_area"
                               name="action_reserve_lot_on_public_area" :disabled="disableInputs"
                               v-model="publicAreaConfig.action_reserve_lot_on_public_area">
                        <label class="custom-control-label pointer" for="action_reserve_lot_on_public_area">
                            {{ t('CONFIG_PUBLIC_AREA.ALLOW_TO_RESERVE_A_LOT_IN_PUBLIC_AREAS') }}
                            <i title="Permite fazer a reserva de um lote em áreas públicas e no mapa"
                               class="ml-1 icon-info-with-circle"/>
                        </label>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input"
                               id="action_calculate_financing_on_public_area"
                               name="action_calculate_financing_on_public_area" :disabled="disableInputs"
                               v-model="publicAreaConfig.action_calculate_financing_on_public_area">
                        <label class="custom-control-label pointer" for="action_calculate_financing_on_public_area">
                            {{ t('CONFIG_PUBLIC_AREA.ALLOW_FINANCIAL_CALCULATOR_IN_PUBLIC_AREAS') }}
                            <i title="Exibe o botão para fazer cálculo de financiamento nas áreas públicas e mapa"
                               class="ml-1 icon-info-with-circle"/>
                        </label>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" id="action_view_lot_data_on_public_area"
                               name="action_view_lot_data_on_public_area" :disabled="disableInputs"
                               v-model="publicAreaConfig.action_view_lot_data_on_public_area">
                        <label class="custom-control-label pointer" for="action_view_lot_data_on_public_area">
                            {{ t('CONFIG_PUBLIC_AREA.ALLOW_VIEWING_OF_LOT_DATA_IN_THE_PUBLIC_AREAS') }}
                            <i title="Exibe o modal com as informações quando clica sobre o lote em áreas públicas e mapa"
                               class="ml-1 icon-info-with-circle"/>
                        </label>
                    </div>
                </div>
            </div>
            <div class="border border-dark p-2 rounded mt-1"><b>Labels:</b>
                <div class="row gutters mt-2">
                    <div class="col-xl-2 col-lg-8 col-md-12 col-sm-12 col-12">
                        <div class="form-group">
                            <label for="situation_label">Situação:</label>
                            <input type="text" id="situation_label" v-model="situation.label" name="situation_label"
                                   class="form-control" :disabled="disableInputs">
                        </div>
                    </div>
                    <div class="col-xl-1 col-lg-8 col-md-12 col-sm-12 col-12">
                        <div class="form-group">
                            <label for="block_label">Quadra:</label>
                            <input type="text" id="block_label" v-model="block.label" name="block_label"
                                   class="form-control" :disabled="disableInputs">
                        </div>
                    </div>
                    <div class="col-xl-1 col-lg-8 col-md-12 col-sm-12 col-12">
                        <div class="form-group">
                            <label for="lot_label">Lote:</label>
                            <input type="text" id="lot_label" v-model="lot.label" name="lot_label"
                                   class="form-control" :disabled="disableInputs">
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-8 col-md-12 col-sm-12 col-12">
                        <div class="form-group">
                            <label for="total_area_label">Metragem:</label>
                            <input type="text" id="total_area_label" v-model="total_area.label"
                                   name="total_area_label"
                                   class="form-control" :disabled="disableInputs">
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-8 col-md-12 col-sm-12 col-12">
                        <div class="form-group">
                            <label for="back_size_label">Fundo:</label>
                            <input type="text" id="back_size_label" v-model="back_size.label" name="back_size_label"
                                   class="form-control" :disabled="disableInputs">
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-8 col-md-12 col-sm-12 col-12">
                        <div class="form-group">
                            <label for="right_size_label">Lateral Direita:</label>
                            <input type="text" id="right_size_label" v-model="right_size.label" name="right_size_label"
                                   class="form-control" :disabled="disableInputs">
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-8 col-md-12 col-sm-12 col-12">
                        <div class="form-group">
                            <label for="left_size_label">Lateral Esquerda:</label>
                            <input type="text" id="left_size_label" v-model="left_size.label" name="left_size_label"
                                   class="form-control" :disabled="disableInputs">
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-8 col-md-12 col-sm-12 col-12">
                        <div class="form-group">
                            <label for="front_size_label">Frente:</label>
                            <input type="text" id="front_size_label" v-model="front_size.label" name="front_size_label"
                                   class="form-control" :disabled="disableInputs">
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-8 col-md-12 col-sm-12 col-12">
                        <div class="form-group">
                            <label for="chamfer_size_label">Chanfro:</label>
                            <input type="text" id="chamfer_size_label" v-model="chamfer_size.label"
                                   name="chamfer_size_label"
                                   class="form-control" :disabled="disableInputs">
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-8 col-md-12 col-sm-12 col-12">
                        <div class="form-group">
                            <label for="price_label">Valor:</label>
                            <input type="text" id="price_label" v-model="price.label" name="price_label"
                                   class="form-control" :disabled="disableInputs">
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-8 col-md-12 col-sm-12 col-12">
                        <div class="form-group">
                            <label for="meter_value_label">Valor do Metro:</label>
                            <input type="text" id="meter_value_label" v-model="meter_value.label"
                                   name="meter_value_label"
                                   class="form-control" :disabled="disableInputs">
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-8 col-md-12 col-sm-12 col-12">
                        <div class="form-group">
                            <label for="front_confrontation_label">Confrontação de frente:</label>
                            <input type="text" id="front_confrontation_label" v-model="front_confrontation.label"
                                   name="front_confrontation_label" class="form-control" :disabled="disableInputs">
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-8 col-md-12 col-sm-12 col-12">
                        <div class="form-group">
                            <label for="allotment_name_label">Loteamento:</label>
                            <input type="text" id="allotment_name_label" v-model="allotment_name.label"
                                   name="allotment_name_label"
                                   class="form-control" :disabled="disableInputs">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row gutters align-items-start">
                <div class="row col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 mt-2">
                    <div class="col-xl-12 col-lg-6 col-md-6 col-sm-6 col-12">
                        <label>Exibir:</label>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="situation_show"
                                   name="situation_show" :disabled="disableInputs" v-model="situation.show">
                            <label class="custom-control-label pointer" for="situation_show">Situação</label>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="block_show"
                                   name="block_show" :disabled="disableInputs" v-model="block.show">
                            <label class="custom-control-label pointer" for="block_show">Quadra</label>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="lot_show" name="lot_show"
                                   :disabled="disableInputs" v-model="lot.show">
                            <label class="custom-control-label pointer" for="lot_show">Lote</label>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="front_size_show"
                                   name="front_size_show" :disabled="disableInputs" v-model="front_size.show">
                            <label class="custom-control-label pointer" for="front_size_show">Frente</label>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="total_area_show"
                                   name="total_area_show" :disabled="disableInputs" v-model="total_area.show">
                            <label class="custom-control-label pointer" for="total_area_show">Metragem</label>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="back_size_show"
                                   name="back_size_show" :disabled="disableInputs" v-model="back_size.show">
                            <label class="custom-control-label pointer" for="back_size_show">Fundo</label>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="chamfer_size_show"
                                   name="chamfer_size_show" :disabled="disableInputs" v-model="chamfer_size.show">
                            <label class="custom-control-label pointer" for="chamfer_size_show">Chanfro</label>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="price_show"
                                   name="price_show" :disabled="disableInputs" v-model="price.show">
                            <label class="custom-control-label pointer" for="price_show">Valor</label>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="allotment_name_show"
                                   name="allotment_name_show" :disabled="disableInputs" v-model="allotment_name.show">
                            <label class="custom-control-label pointer" for="allotment_name_show">Loteamento</label>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="right_size_show"
                                   name="right_size_show" :disabled="disableInputs" v-model="right_size.show">
                            <label class="custom-control-label pointer" for="right_size_show">Lateral Direita</label>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="left_size_show"
                                   name="left_size_show" :disabled="disableInputs" v-model="left_size.show">
                            <label class="custom-control-label pointer" for="left_size_show">Lateral Esquerda</label>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="meter_value_show"
                                   name="meter_value_show" :disabled="disableInputs" v-model="meter_value.show">
                            <label class="custom-control-label pointer" for="meter_value_show">Valor do Metro</label>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="front_confrontation_show"
                                   name="front_confrontation_show" :disabled="disableInputs"
                                   v-model="front_confrontation.show">
                            <label class="custom-control-label pointer" for="front_confrontation_show">Confrontação de
                                Frente</label>
                        </div>
                    </div>
                </div>
                <div class="row col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 mt-2">
                    <div class="col-xl-12 col-lg-6 col-md-6 col-sm-6 col-12 ml-4 mt-2">
                        <label>Exibição na área pública:</label>
                    </div>
                    <div class="modal-dialog modal-sm">
                        <div class="modal-content"
                             :style="!publicAreaConfig.action_view_lot_data_on_public_area ? {backgroundColor: '#dadcdd'} : {}">
                            <div class="modal-body text-center">
                                <h4>{{ `${t('GENERAL.INFORMATION')} ${lot.label}` }}</h4>

                                <div class="info-stats4 w-100 full-border"
                                     :style="!publicAreaConfig.action_view_lot_data_on_public_area ? {backgroundColor: '#dadcdd'} : {}">
                                    <div class="row gutters">
                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3"/>
                                        <div class="info-lot col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6"
                                             :style="{'background-color': '#99fb98', color: '#000'}">
                                            <h3>{{ exhibition.lot }}</h3>
                                            <h5 v-if="situation.show">{{ exhibition.situation }}</h5>
                                        </div>

                                        <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1"/>
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                        </div>
                                        <h4 v-if="price.show" class="font-weight-bold mt-1" style="width: 100%">
                                            {{ exhibition.price }}
                                        </h4>
                                        <div class="sale-num col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1"></div>
                                        <div v-if="block.show"
                                             class="mt-1 sale-num col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5"><p
                                            class="secondary">
                                            {{ `${block.label} ${exhibition.block}` }}
                                        </p></div>
                                        <div v-if="total_area.show"
                                             class="mt-1 sale-num col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5"><p
                                            class="secondary">
                                            {{ exhibition.total_area }}
                                        </p></div>
                                        <div class="sale-num col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1"></div>
                                    </div>
                                </div>
                                <div class="space-between" :style="{'justify-content': formatButtons()}">
                                    <button class="btn btn-primary mr-1"
                                            v-if="publicAreaConfig.action_reserve_lot_on_public_area">
                                        {{ t('LOTS.RESERVE') }}
                                    </button>
                                    <button class="btn btn-primary mr-1"
                                            v-if="publicAreaConfig.action_calculate_financing_on_public_area">
                                        {{ t('GENERAL.SIMULATOR') }}
                                    </button>
                                </div>
                                <span v-if="!publicAreaConfig.action_view_lot_data_on_public_area"
                                      class="mr-auto font-weight-bold" style="font-size: 10px; color: red">
                                    {{
                                       t('CONFIG_PUBLIC_AREA.THIS_MODAL_WILL_NOT_BE_DISPLAYED_WHEN_CLICKING_ON_THE_LOT')
                                    }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div class="col-xl-12 col-lg-6 col-md-6 col-sm-6 col-12 mt-2">
                        <label>Exibição no mapa (valores apenas para demonstração):</label>
                    </div>
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content"
                             :style="!publicAreaConfig.action_view_lot_data_on_public_area ? {backgroundColor: '#dadcdd'} : {}">
                            <div class="modal-header" v-if="situation.show">
                                <h4 class="modal-title"><b>{{ situation.label }}:</b>
                                    <span class="ml-2" id="situacao">{{
                                            exhibition.situation
                                        }}</span>
                                </h4>
                            </div>
                            <div class="modal-body">
                                <div class="row gutters">
                                    <div class="row col-xl-12 col-md-12">
                                        <p v-if="front_confrontation.show" class="col-xl-6 col-md-6">
                                            <b>{{ front_confrontation.label }}:</b>
                                            <span class="ml-2" id="confrontacao_frente">{{
                                                    exhibition.front_confrontation
                                                }}</span>
                                        </p>
                                        <p v-if="allotment_name.show" class="col-xl-6 col-md-6">
                                            <b>{{ allotment_name.label }}:</b>
                                            <span class="ml-2" id="loteamento">{{ exhibition.allotment_name }}</span>
                                        </p>
                                        <p v-if="block.show" class="col-xl-4 col-md-4"><b>{{ block.label }}:</b>
                                            <span class="ml-2" id="quadra">
                                                {{ exhibition.block }}
                                            </span>
                                        </p>
                                        <p v-if="lot.show" class="col-xl-4 col-md-4"><b>{{ lot.label }}:</b>
                                            <span class="ml-2" id="lote">{{ exhibition.lot }}</span>
                                        </p>
                                        <p v-if="front_size.show" class="col-xl-4 col-md-4"><b>{{
                                                front_size.label
                                            }}:</b>
                                            <span class="ml-2" id="frente">{{ exhibition.front_size }}</span>
                                        </p>
                                        <p v-if="back_size.show" class="col-xl-4 col-md-4"><b>{{ back_size.label }}:</b>
                                            <span class="ml-2" id="fundo">{{ exhibition.back_size }}</span>
                                        </p>
                                        <p v-if="right_size.show" class="col-xl-4 col-md-4">
                                            <b>{{ right_size.label }}:</b>
                                            <span class="ml-2" id="lateral_direita">{{ exhibition.right_size }}</span>
                                        </p>
                                        <p v-if="left_size.show" class="col-xl-4 col-md-4"><b>{{ left_size.label }}:</b>
                                            <span class="ml-2" id="lateral_esquerda">{{ exhibition.left_size }}</span>
                                        </p>
                                        <p v-if="chamfer_size.show" class="col-xl-4 col-md-4"><b>{{
                                                chamfer_size.label
                                            }}:</b>
                                            <span class="ml-2" id="chanfro">{{ exhibition.chamfer_size }}</span>
                                        </p>
                                        <p v-if="total_area.show" class="col-xl-4 col-md-4"><b>{{
                                                total_area.label
                                            }}:</b>
                                            <span class="ml-2" id="total_area">{{ exhibition.total_area }}</span>
                                        </p>
                                        <p v-if="price.show" class="col-xl-4 col-md-4" id="label_valor"><b>{{
                                                price.label
                                            }}:</b>
                                            <span class="ml-2" id="valor">{{ exhibition.price }}</span>
                                        </p>
                                        <p v-if="meter_value.show" class="col-xl-4 col-md-4" id="label_meter_value">
                                            <b>{{ meter_value.label }}:</b>
                                            <span class="ml-2" id="meter_value">{{ exhibition.meter_value }}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                            <span v-if="!publicAreaConfig.action_view_lot_data_on_public_area"
                                  class="mr-auto font-weight-bold" style="font-size: 10px; color: red">
                                {{
                                   t('CONFIG_PUBLIC_AREA.THIS_MODAL_WILL_NOT_BE_DISPLAYED_WHEN_CLICKING_ON_THE_LOT')
                                }}</span>
                                <button type="button" class="btn btn-primary"
                                        style="background-color: #357bb5; border-color: #386992">Voltar
                                </button>
                                <button class="btn btn-primary hidden"
                                        v-if="publicAreaConfig.action_calculate_financing_on_public_area"
                                        style="background-color: #357bb5; border-color: #386992"
                                        id="btn_modal_calculo_financiamento">Calcular
                                    Financiamento
                                </button>
                                <button class="btn btn-primary hidden"
                                        v-if="publicAreaConfig.action_reserve_lot_on_public_area"
                                        style="background-color: #357bb5; border-color: #386992"
                                        id="btn_reservar_lote">Reservar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {useI18n} from 'vue-i18n';
import {useToast} from 'vue-toastification';
import ConfigPublicArea from '@/services/ConfigPublicArea';
import PageHeader from '@/components/layouts/pageHeader.vue';

export default {
    name: 'createConfigPublicAreaComponent',

    components: {
        PageHeader,
    },

    setup() {
        const {t} = useI18n();
        const toast = useToast();

        return {t, toast};
    },

    mounted() {
        this.setAction();
        (this.action === 'add') ? this.setDefaultData() : this.show();
    },

    data() {
        return {
            action: 'add',
            publicAreaConfig: {},
            disableInputs: false,
            title: 'GENERAL.EMPTY',
            asViewDefaultConfig: false,

            situation: {},
            block: {},
            lot: {},
            total_area: {},
            back_size: {},
            right_size: {},
            left_size: {},
            front_size: {},
            chamfer_size: {},
            price: {},
            meter_value: {},
            front_confrontation: {},
            allotment_name: {},

            exhibition: {
                situation: 'Disponível',
                front_confrontation: 'Rua 9',
                allotment_name: 'Loteamento Exemplo',
                block: '007',
                lot: '0302',
                front_size: '12',
                back_size: '12',
                right_size: '30',
                left_size: '30',
                chamfer_size: '',
                total_area: '250 M²',
                price: 'R$ 140.000,00',
                meter_value: 'R$ 560,00',
            },
        };
    },

    methods: {
        setAction() {
            switch (this.$route.params.action) {
                case 'adicionar':
                    this.action = 'add';
                    this.title = 'CONFIG_PUBLIC_AREA.NEW_CONFIG';
                    break;
                case 'editar':
                    this.action = 'edit';
                    this.title = 'CONFIG_PUBLIC_AREA.EDIT_CONFIG';
                    if ((this.$route.params.id === '1')) {
                        this.toast.warning(this.t('CONFIG_PUBLIC_AREA.NOT_POSSIBLE_EDIT_DEFAULT_PUBLIC_AREA_CONFIG'));
                        return this.$router.push({name: 'indexConfigPublicArea'});
                    }
                    break;
                default:
                    this.disableInputs = true;
                    this.action = 'view';
                    this.asViewDefaultConfig = (this.$route.params.id === '1');
                    this.title = this.asViewDefaultConfig ? 'CONFIG_PUBLIC_AREA.VIEW_CONFIG_DEFAULT' : 'CONFIG_PUBLIC_AREA.VIEW_CONFIG';
            }
        },

        getIcon() {
            if (this.asViewDefaultConfig) return '';
            if (this.action === 'view') return 'icon-mode_edit';

            return 'icon-save';
        },

        getTitleButton() {
            if (this.asViewDefaultConfig) return '';
            if (this.action === 'view') return 'ACTIONS.EDIT';

            return 'GENERAL.SAVE';
        },

        formatButtons() {
            return !this.publicAreaConfig.action_reserve_lot_on_public_area ? 'end !important' : 'space-between !important';
        },

        show() {
            this.$store.commit('changeLoading', true);

            ConfigPublicArea.show(this.$route.params.id).then(resp => {
                delete resp.data.default;
                this.publicAreaConfig.name = resp.data.name;
                this.publicAreaConfig.action_show_maps_on_public_area = resp.data.action_show_maps_on_public_area;
                this.publicAreaConfig.action_calculate_financing_on_public_area = resp.data.action_calculate_financing_on_public_area;
                this.publicAreaConfig.action_reserve_lot_on_public_area = resp.data.action_reserve_lot_on_public_area;
                this.publicAreaConfig.action_view_lot_data_on_public_area = resp.data.action_view_lot_data_on_public_area;
                this.situation = resp.data.situation;
                this.block = resp.data.block;
                this.lot = resp.data.lot;
                this.total_area = resp.data.total_area;
                this.back_size = resp.data.back_size;
                this.right_size = resp.data.right_size;
                this.left_size = resp.data.left_size;
                this.front_size = resp.data.front_size;
                this.chamfer_size = resp.data.chamfer_size;
                this.price = resp.data.price;
                this.meter_value = resp.data.meter_value;
                this.front_confrontation = resp.data.front_confrontation;
                this.allotment_name = resp.data.allotment_name;
            }).catch(err => this.errorMsg(err)).finally(() => this.$store.commit('changeLoading', false));
        },

        toEdit() {
            this.$router.replace({params: {action: 'editar'}});
            this.action = 'edit';
            this.title = 'CONFIG_PUBLIC_AREA.EDIT_CONFIG';
            if ((this.$route.params.id === '1')) {
                this.toast.warning(this.t('CONFIG_PUBLIC_AREA.NOT_POSSIBLE_EDIT_DEFAULT_PUBLIC_AREA_CONFIG'));
                return this.$router.push({name: 'indexConfigPublicArea'});
            }
            this.disableInputs = false;
        },

        setDefaultData() {
            this.publicAreaConfig = {
                name: 'Nova Configuração',
                action_show_maps_on_public_area: true,
                action_reserve_lot_on_public_area: true,
                action_calculate_financing_on_public_area: true,
                action_view_lot_data_on_public_area: true,
            };
            this.situation = {show: true, label: 'Situação'};
            this.block = {show: true, label: 'Quadra'};
            this.lot = {show: true, label: 'Lote'};
            this.total_area = {show: true, label: 'Metragem'};
            this.back_size = {show: true, label: 'Fundo'};
            this.right_size = {show: true, label: 'Lateral direita'};
            this.left_size = {show: true, label: 'Lateral esquerda'};
            this.front_size = {show: true, label: 'Frente'};
            this.chamfer_size = {show: true, label: 'Chanfro'};
            this.price = {show: true, label: 'Valor'};
            this.meter_value = {show: true, label: 'Valor do metro'};
            this.front_confrontation = {show: true, label: 'Confrontação de frente'};
            this.allotment_name = {show: true, label: 'Loteamento'};
        },

        async save() {
            if (this.checkForm()) {
                this.publicAreaConfig.company_id = localStorage.getItem('companyId');
                this.publicAreaConfig.situation = this.situation;
                this.publicAreaConfig.block = this.block;
                this.publicAreaConfig.lot = this.lot;
                this.publicAreaConfig.total_area = this.total_area;
                this.publicAreaConfig.back_size = this.back_size;
                this.publicAreaConfig.right_size = this.right_size;
                this.publicAreaConfig.left_size = this.left_size;
                this.publicAreaConfig.front_size = this.front_size;
                this.publicAreaConfig.chamfer_size = this.chamfer_size;
                this.publicAreaConfig.price = this.price;
                this.publicAreaConfig.meter_value = this.meter_value;
                this.publicAreaConfig.front_confrontation = this.front_confrontation;
                this.publicAreaConfig.allotment_name = this.allotment_name;

                (this.action === 'edit') ? this.upsert(ConfigPublicArea.update({
                        ...this.publicAreaConfig,
                        id: this.$route.params.id,
                    }), 'atualizada') :
                    this.upsert(ConfigPublicArea.create(this.publicAreaConfig), 'adicionada');
            }
        },

        upsert(promise, word) {
            this.$store.commit('changeLoading', true);

            promise.then(() => {
                this.toast.success(`Configuração ${word} com sucesso!`);
                this.$router.push({name: 'indexConfigPublicArea'});
                this.publicAreaConfig = {};
            }).catch(err => this.errorMsg(err)).finally(() => this.$store.commit('changeLoading', false));
        },

        checkForm() {
            if (!this.publicAreaConfig.name) {
                this.toast.error('É necessário informar um nome para a configuração');
                return false;
            }

            return true;
        },
    },
};

</script>

<style scoped>
.modal-dialog {
    margin-top: 0rem;
}

.secondary {
    border-radius: 3px;
    color: #ffffff !important;
    background-color: #383737;
}

.info-lot {
    border-radius: 7px;
    padding-bottom: 1px;
    padding-top: 6px;
}

.space-between {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-bottom-right-radius: calc(.3rem - 1px);
    border-bottom-left-radius: calc(.3rem - 1px);
}
</style>